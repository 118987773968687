<template>
  <v-layout row justify-center align-center wrap class="mt-4 pt-2">
    <v-flex xs12 sm12 md5 lg6 xl6 class="mt-4 pt-4">
      <h2 class="mb-4 pl-4 pt-2">Education</h2>
      <v-card-text class="py-0">
        <v-timeline align-top dense>
          <v-timeline-item color="green" small>
            <v-layout pt-3>
              <v-flex xs4>
                <div class="caption font-weight-bold green--text">FEB 2021 -</div>
                <div class="caption font-weight-bold green--text">DEC 2022</div>
              </v-flex>
              <v-flex class="pl-2">
                <strong>Masters Of Science In Computer Sciences</strong>
                <div class="caption mb-2">Maharishi International University</div>
              </v-flex>
            </v-layout>
          </v-timeline-item>

          <v-timeline-item color="green" small>
            <v-layout pt-3>
              <v-flex xs4>
                <div class="caption font-weight-bold green--text">OCT 2015 -</div>
                <div class="caption font-weight-bold green--text">MAY 2019</div>
              </v-flex>
              <v-flex class="pl-2">
                <strong>Bachelors Of Science In Computer Sciences</strong>
                <div class="caption mb-2">University Of Lahore</div>
              </v-flex>
            </v-layout>
          </v-timeline-item>

          <v-timeline-item color="green" small>
            <v-layout pt-3>
              <v-flex xs4>
                <div class="caption font-weight-bold green--text">AUG 2013 -</div>
                <div class="caption font-weight-bold green--text">JUN 2015</div>
              </v-flex>
              <v-flex class="pl-2">
                <strong>Intermediate In Computer Sciences</strong>
                <div class="caption mb-2">HIT Degree College, Taxila Cantt</div>
              </v-flex>
            </v-layout>
          </v-timeline-item>
          <v-timeline-item color="green" small>
                      <v-layout pt-3>
                        <v-flex xs4>
                          <div class="caption font-weight-bold green--text">AUG 2002 -</div>
                          <div class="caption font-weight-bold green--text">MAR 2013</div>
                        </v-flex>
                        <v-flex class="pl-2">
                          <strong>Matriculation In Biological Sciences</strong>
                          <div class="caption mb-2">F.G Public High School, Taxila Cantt</div>
                        </v-flex>
                      </v-layout>
                    </v-timeline-item>
        </v-timeline>
      </v-card-text>
    </v-flex>
    <v-flex xs12 sm12 md4 lg6 xl6 class="mt-4 pt-2">
      <h2 class="mb-4 pl-4">Experience</h2>
      <v-card-text class="py-0">
        <v-timeline align-top dense>
        <v-timeline-item color="green" small>
                    <v-layout pt-3>
                      <v-flex xs4>
                        <div class="caption font-weight-bold green--text">OCT 2021 -</div>
                        <div class="caption font-weight-bold green--text">PRESENT</div>
                      </v-flex>
                      <v-flex class="pl-2">
                        <strong>Software Developer</strong>
                        <div class="caption mb-2">Deloitte</div>
                      </v-flex>
                    </v-layout>
                  </v-timeline-item>
          <v-timeline-item color="green" small>
            <v-layout pt-3>
              <v-flex xs4>
                <div class="caption font-weight-bold green--text">OCT 2019 -</div>
                <div class="caption font-weight-bold green--text">NOV 2020</div>
              </v-flex>
              <v-flex class="pl-2">
                <strong>Software Engineer</strong>
                <div class="caption mb-2">Programmers Force</div>
              </v-flex>
            </v-layout>
          </v-timeline-item>

          <v-timeline-item color="green" small>
            <v-layout pt-3>
              <v-flex xs4>
                <div class="caption font-weight-bold green--text">MAR 2017 -</div>
                <div class="caption font-weight-bold green--text">OCT 2019</div>
              </v-flex>
              <v-flex class="pl-2">
                <strong>Web Developer</strong>
                <div class="caption mb-2">Master Paints</div>
              </v-flex>
            </v-layout>
          </v-timeline-item>

          <v-timeline-item color="green" small>
            <v-layout pt-3>
              <v-flex xs4>
                <div class="caption font-weight-bold green--text">JUL 2013 -</div>
                <div class="caption font-weight-bold green--text">SEP 2013</div>
              </v-flex>
              <v-flex class="pl-2">
                <strong>Web Developer Intern</strong>
                <div class="caption mb-2">Creative On, Lahore</div>
              </v-flex>
            </v-layout>
          </v-timeline-item>
        </v-timeline>
      </v-card-text>
    </v-flex>
    <v-layout row justify-center align-center wrap class="mt-4 pt-2">
      <v-flex xs12 sm12 md4 lg5 xl5 class="mx-2 pt-4">
        <h2>
          <span>Design</span>

          <span class="green--text">Skills</span>
        </h2>
        <br>
        <strong>HTML/CSS</strong>
        <v-progress-linear v-model="buffer1" :buffer-value="bufferValue" color="green" height="16"></v-progress-linear>
        <strong>UI/UX Principles</strong>
        <v-progress-linear v-model="buffer2" :buffer-value="bufferValue" color="green" height="16"></v-progress-linear>
        <strong>Photoshop/Illustrator</strong>
        <v-progress-linear v-model="buffer3" :buffer-value="bufferValue" color="green" height="16"></v-progress-linear>
      </v-flex>
      <v-flex xs12 sm12 md4 lg5 xl5 class="mx-2 pt-4">
        <h2>
          <span>Coding</span>
          <span class="green--text">Skills</span>
        </h2>
        <br>
        <strong>SQL Databases</strong>
        <v-progress-linear v-model="buffer4" :buffer-value="bufferValue" color="green" height="16"></v-progress-linear>
        <strong>Java/Spring</strong>
        <v-progress-linear v-model="buffer5" :buffer-value="bufferValue" color="green" height="16"></v-progress-linear>
        <strong>VUE/JavaScript</strong>
        <v-progress-linear v-model="buffer6" :buffer-value="bufferValue" color="green" height="16"></v-progress-linear>
      </v-flex>
      <div class="mt-4 pt-2">
        <v-btn
          href="https://drive.google.com/file/d/1M4ByYste5LJUq9OHqKPQ3igxkdtD-lGu/view?usp=sharing"
          target="_blank"
          color="green"
          dark
          large
        >
          Download Resume
          <v-icon right dark>fas fa-file-download</v-icon>
        </v-btn>
      </div>
    </v-layout>
  </v-layout>
</template>

<script>
export default {
  metaInfo: {
    title: 'Resume',
    titleTemplate: "%s ← Danyal's Space",
    meta: [
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      {
        name: 'description',
        content:
          'Vue NUXT HTML CSS JavaScript Design Adobe XD Photoshop Coding Skills Resume Work Web Developer Front-End'
      },
      { charset: 'utf-8' },
      { property: 'og:title', content: "Danyal' Space" },
      { property: 'og:site_name', content: "Danyal' Space" },
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: 'https://mrcasm.com' },
      {
        property: 'og:image',
        content: 'https://i.imgur.com/Dcz2PGx.jpg'
      },
      {
        property: 'og:description',
        content:
          'Vue NUXT HTML CSS JavaScript Design Adobe XD Photoshop Coding Skills Resume Work Web Developer Front-End'
      }
    ]
  },
  data () {
    return {
      value: 0,
      buffer1: 0,
      buffer2: 0,
      buffer3: 0,
      buffer4: 0,
      buffer5: 0,
      buffer6: 0,
      bufferValue: 100,
      interval: 0
    }
  },
  mounted () {
    this.startBuffer()
  },

  beforeDestroy () {
    clearInterval(this.interval)
  },

  methods: {
    startBuffer () {
      this.interval = setInterval(() => {
        this.buffer1 = 80
        this.buffer2 = 85
        this.buffer3 = 87
        this.buffer4 = 93
        this.buffer5 = 95
        this.buffer6 = 70
      }, 800)
    }
  }
}
</script>
<style >
</style>
