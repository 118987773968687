<template>
  <v-container grid-list-xl>
    <h2 class="pl-4">
      <span>Web</span>
      <span class="green--text">Portfolio</span>
    </h2>
    <v-layout row justify-center align-center wrap class="mt-4 pt-2">
      <v-dialog
        v-model="project.dialog"
        lazy
        max-width="1000"
        v-for="project in projects"
        :key="project.title"
      >
        <template v-slot:activator="{ on }">
          <v-flex xs12 sm6 md4 lg4 xl4 v-on="on">
            <v-card hover flat color="transparent">
              <v-img
                :src="project.poster"
                :alt="project.title"
                height="230"
                lazy-src="https://cdn.dribbble.com/users/503653/screenshots/3143656/fluid-loader.gif"
              ></v-img>
              <v-card-title primary-title class="justify-center">{{project.title}}</v-card-title>
            </v-card>
          </v-flex>
        </template>
        <v-card v-if="project.dialog">
          <v-img :src="project.poster"></v-img>
          <v-card-text>
            <h3 class="headline mb-0">
              <span>Technology</span>
            </h3>
            <v-chip color="green" text-color="white">{{project.tech.tech1}}</v-chip>
            <v-chip color="green" text-color="white">{{project.tech.tech2}}</v-chip>
            <v-chip color="green" text-color="white">{{project.tech.tech3}}</v-chip>
            <v-chip color="green" text-color="white">{{project.tech.tech4}}</v-chip>
          </v-card-text>
          <v-card-actions>
            <v-btn flat large dark color="green" :href="project.git" target="_blank">
              <v-icon left>fab fa-github</v-icon>GitHub
            </v-btn>
            <v-btn large flat dark color="green" :href="project.demo" target="_blank">
              <v-icon left>fas fa-desktop</v-icon>Demo
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  metaInfo: {
    title: 'Web Portfolio',
    titleTemplate: "%s ← Danyal's Space",
    meta: [
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      {
        name: 'description',
        content:
          "Danyal Javed's Web Portfolio Vue Vue.js Nuxt Nuxt.js HTML CSS Vuetify Axios GraphQL JavaScript Web Developer Front-End Frontend Designer App Responsive"
      },
      { charset: 'utf-8' },
      { property: 'og:title', content: "Danyal' Space" },
      { property: 'og:site_name', content: "Danyal' Space" },
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: 'https://mrcasm.com' },
      {
        property: 'og:image',
        content: 'https://i.imgur.com/Dcz2PGx.jpg'
      },
      {
        property: 'og:description',
        content:
          "Danyal Javed's Web Portfolio Vue Vue.js Nuxt Nuxt.js HTML CSS Vuetify Axios GraphQL JavaScript Web Developer Front-End Frontend Designer App Responsive"
      }
    ]
  },
  data () {
    return {
      dialog: false,
      projects: [
        {
          dialog: false,
          title: 'MeadowBrook is Providing at-home professional care in US.',
          git: 'https://github.com',
          demo: 'https://meadowbrookhha.com/',
          tech: {
            tech1: 'PHP',
            tech2: 'Wordpress',
            tech3: 'HTML/CSS',
            tech4: 'JavaScript'
          },
          poster: '/mbw.JPG'
        },
        {
          dialog: false,
          title: 'Halal Realtors a Realstate Platform',
          git: 'https://github.com/',
          demo: 'https://halalrealtors.com/',
          tech: {
            tech1: 'PHP',
            tech2: 'Wordpress',
            tech3: 'HTML/CSS',
            tech4: 'JavaScript'
          },
          poster: '/hrw.JPG'
        },
        {
          dialog: false,
          title: 'Full Stack Developer at Quickbit.eu (Buy Digital Currency)',
          git: 'https://github.com',
          demo: 'https://quickbit.eu/',
          tech: {
            tech1: 'PHP/Laravel',
            tech2: "JSON/API's",
            tech3: 'HTML/CSS/Bootstrap',
            tech4: 'JavaScript/jQuery'
          },
          poster: '/qbw.JPG'
        },
        {
          dialog: false,
          title: 'Master Paints Ecommerce Store',
          git: 'https://github.com/',
          demo: 'https://masterpaints.com/',
          tech: {
            tech1: 'PHP',
            tech2: 'HTML/CSS',
            tech3: 'JavaScript/jQuery',
            tech4: 'SCSS'
          },
          poster: '/mpw.JPG'
        },
        {
          dialog: false,
          title: 'Photocalypse - Register - Recognize Your Face - Download',
          git: 'https://github.com',
          demo: 'http://photocalypse.tk/',
          tech: {
            tech1: 'Python',
            tech2: 'PHP',
            tech3: 'HTML/CSS',
            tech4: 'JavaScript/jQuery'
          },
          poster: '/pcw.JPG'
        },
        {
          dialog: false,
          title: 'Zahanat.com a free online resource for Pakistan’s academic communities.',
          git: 'https://github.com/',
          demo: 'https://zahanat.com/',
          tech: {
            tech1: 'Wordpress',
            tech2: 'HTML/CSS',
            tech3: 'JavaScript',
            tech4: 'SEO'
          },
          poster: '/zntw.JPG'
        }
        // {
        //   dialog: false,
        //   title: "Stock Trader",
        //   git: "https://github.com/",
        //   demo: "https://netlify.com/",
        //   tech: {
        //     tech1: "VUE",
        //     tech2: "Vuex",
        //     tech3: "Firebase",
        //     tech4: "JavaScript"
        //   },
        //   poster: "https://i.imgur.com/wK5dqP7.png"
        // },
        // {
        //   dialog: false,
        //   title: "Riders Share Newsleter",
        //   git: "",
        //   demo: "https://imgur.com/zTMJhGk",
        //   tech: {
        //     tech1: "HTML",
        //     tech2: "CSS",
        //     tech3: "MailChimp",
        //     tech4: "JavaScript"
        //   },
        //   poster: "https://i.imgur.com/zTMJhGk.jpg"
        // },
        // {
        //   dialog: false,
        //   title: "My Old Web Portfolio",
        //   git: "https://github.com",
        //   demo: "https://github.io/",
        //   tech: {
        //     tech1: "HTML",
        //     tech2: "CSS",
        //     tech3: "JavaScript",
        //     tech4: "jQuery"
        //   },
        //   poster: "https://i.imgur.com/AW7CXD5.jpg"
        // }
      ]
    }
  }
}
</script>

<style  scoped>
</style>
